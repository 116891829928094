<template>
    <div id="newTagPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newTag"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="tag_registerNewBLETag"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("tag_registerNewBLETag") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group form-group-last">
                                                    <div class="alert alert-secondary" role="alert">
                                                        <div class="alert-icon"><i class="flaticon-info kt-font-brand"></i></div>
                                                        <div class="alert-text">
                                                            {{ $t("tag_newTagFormHelp") }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row validated">
                                                    <div class="col-lg-6">
                                                        <label for="newTagForm_serialNumberInput">{{ $t("tag_serialNumberLabel") }}</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-barcode"></i></span>
                                                            </div>
                                                            <input v-model="serialNumber" @input="$v.serialNumber.$touch()" type="text" class="form-control" id="newTagForm_serialNumberInput" :placeholder="$t('tag_enterSerialNumber')" />
                                                            <div v-if="!$v.serialNumber.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="!$v.serialNumber.minLen" class="invalid-feedback">
                                                                {{ $t("error_minLengthMsg", [$v.serialNumber.$params.minLen.min]) }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted"> {{ $t("tag_serialNumberExample") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="newTagForm_macAddressInput">{{ $t("tag_macAddressLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-code"></i></span>
                                                            </div>
                                                            <input v-model="macAddress" @input="$v.macAddress.$touch()" type="text" class="form-control toUppercase" id="newTagForm_macAddressInput" :placeholder="$t('tag_enterMacAddress')" />
                                                            <div v-if="!$v.macAddress.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="$v.macAddress.$error" class="invalid-feedback">
                                                                {{ $t("error_invalidMacAddress") }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_macAddressExample") }}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-6">
                                                        <label for="batteryInstalDateDatePicker">{{ $t("tag_batteryInstalDateLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-calendar"></i></span>
                                                            </div>
                                                            <input type="text" class="form-control" id="batteryInstalDateDatePicker" readonly="" :placeholder="$t('tag_selectInstalDate')" />
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("tag_batteryInstalDateExample") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="newTagForm_tagTypeInput">{{ $t("tag_tagTypeLabel") }} *</label>
                                                        <div id="newGatewayModel" class="gateway-form-model-input">
                                                            <select id="newTagForm_tagTypeInput" class="form-control kt-select2">
                                                                <option></option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <label class="kt-checkbox kt-margin-r-20">
                                                        <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                        <span></span>
                                                    </label>
                                                    <button id="newTagCreateButton" @click="onCreateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid" data-cy="createTagBtn">{{ $t("common_add") }}</button>
                                                    <button id="newTagCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, macAddress, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";

export default {
    data() {
        return {
            serialNumber: "",
            macAddress: "",
            batteryInstallationDate: moment().toISOString(),
            tagTypeId: "",
            siteId: this.$route.params.siteId,
            createAnother: false,
            tagTypes: [],
            tagModelHeadStyle: "",
            tagModelBodyStyle: "",
            resizeTimeoutId: null
        };
    },
    created: function() {
        console.log("Component(newTag)::created() - called");
        window.addEventListener("resize", this.onResize);
        this.createAnother = this.createAnotherTag;
        this.setCreateAnotherTag(false);
        this.getTagTypes({ siteId: this.siteId });
    },
    mounted: function() {
        console.log("Component(newTag)::mounted() - Init metronic layout");
        KTLayout.init();

        // Init datepicker
        let selector = $("#batteryInstalDateDatePicker");
        commonVueHelper.initDatePickerTag(selector, moment().toDate());
        selector.on("change", () => {
            this.batteryInstallationDate = commonVueHelper.getDatePickerISOString(selector);
        });
    },
    beforeDestroy: function() {
        console.log("Component(newTag)::beforeDestroy() - called");
        // Reset component data
        this.serialNumber = null;
        this.macAddress = null;
        this.batteryInstallationDate = null;
        this.tagTypeId = null;
        this.associatedAsset = null;
        this.tagTypes = [];
        if ($("#batteryInstalDateDatePicker").length !== 0) {
            $("#batteryInstalDateDatePicker").datepicker("destroy");
        }
        // Reset store
        this.resetTagTypesState();
    },
    destroyed: function() {
        console.log("Component(newTag)::destroyed() - called");
        window.removeEventListener("resize", this.onResize);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        serialNumber: {
            required,
            minLen: minLength(4)
        },
        macAddress: {
            required,
            macAddress: macAddress()
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        tagTypeObjsByName: function(list) {
            console.log("Component(newTag)::watch(tagTypeObjsByName) called with : ", list);
            let mySelf = this;
            mySelf.tagTypes = [];
            if (list && Object.keys(list).length > 0) {
                Object.keys(list).forEach(key => {
                    if (list[key].name && list[key].name !== "UNIVERSAL") {
                        mySelf.tagTypes.push(list[key]);
                    }
                });

                setTimeout(() => {
                    const site = this.currentSite;
                    let BLEGatewayType = "";
                    if(site) {
                        BLEGatewayType = site.BLEGatewaysType;
                    }
                    if (BLEGatewayType === "OMNIACCESS_OEM") {
                        _.remove(mySelf.tagTypes, (tagType) => tagType.name === "ASSET");
                    }
                    // Set default selected tagType to the first tag type
                    mySelf.tagTypeId = mySelf.tagTypes[0].id;
                }, 200);
            }

            this.initTagModelsSelect2();
        },

        user: function(user) {
            console.log("Component(newTag)::watch(user) called with : ", user);
            if (user) {
                // Translate tag models
                this.updateTagTypes();
                // Resize tag models spans
                this.onResize();
                this.initTagModelsSelect2();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "createAnotherTag", "tagTypeObjsByName", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["createTag", "setCreateAnotherTag", "getTagTypes", "resetTagTypesState"]),

        getDataTagTypes() {
            const tagtypes = _.map(this.tagTypes, tagType => {
                return { id: tagType.id, text: tagType.fullRef, "data-image": tagType.image};
            })
            return tagtypes;

        },

        initTagModelsSelect2() {
            var self = this;

            const formatState = (state) => {
                // console.log(state)
                if (!state.id) {
                    return state.text
                }
                let tagType = _.find(self.tagTypes, {id: state.id});
                if(!tagType.img) {
                    tagType.img = "/assets/tags/universal.svg";
                }
                let $state = $(
                    `<span> <img src="${tagType.img}" class="tagsIconPreview" style="margin-right: 10px" /> ${state.text} </span>`
                );
                return $state;
            };


            const dataTagModels = self.getDataTagTypes();
            commonVueHelper.destroySelect2($("#newTagForm_tagTypeInput"));
            $("#newTagForm_tagTypeInput")
                .select2({
                    allowClear: true,
                    placeholder: i18n.t("tag_tagEnterTagModel"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        },
                        searching: function () {
                            return i18n.t("common_searchDots");
                        },
                        placeholder: function () {
                            return i18n.t("common_searchBySerial");
                        }
                    },
                    data: dataTagModels,
                    templateResult: formatState
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.tagTypeId = $(this).val();
                });

            // Set value selected
            $("#newTagForm_tagTypeInput").val(dataTagModels[0]['id']).trigger({ type: 'change' });
        },

        // Update tag types translation with current user language
        updateTagTypes() {
            // Saved selected model
            const tagTypeIdSaved = this.tagTypeId;
            this.tagTypeId = null;
            // Update models translation
            for (let i=0; i<this.tagTypes.length; i++) {
                this.tagTypes[i] = commonVueHelper.getTagTypeItem(this.tagTypes[i]);
            }
            // Force refresh tag models
            this.tagTypeId = tagTypeIdSaved;
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(newTag)::onCancelButton() - called");
            this.$router.push({ name: "tags", params: { fromAction: "cancelButton" } });
        },

        onCreateButton() {
            console.log("Component(newTag)::onCreateButton() - called");
            const data = {
                siteId: this.siteId,
                serialNumber: this.serialNumber,
                macAddress: this.macAddress,
                batteryInstallationDate: this.batteryInstallationDate,
                tagTypeId: this.tagTypeId,
                createAnother: this.createAnother
            };
            this.createTag(data);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.serialNumber || this.macAddress) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        /**
         * Function called by "resize" event listener
         */
        onResize(event) {
            console.log("Component(newTag)::onResize() - called", event);
            // No height style, this to render the default height of models style
            this.tagModelHeadStyle = "";
            this.tagModelBodyStyle = "";
            // Waiting all displayed before resize tag models span
            if (this.resizeTimeoutId) {
                clearTimeout(this.resizeTimeoutId);
            }
            this.resizeTimeoutId = setTimeout(this.resizeTagModels, 100);
        },

        /**
         * Resize the tag models with the same height.
         */
        resizeTagModels() {
            console.log("Component(newTag)::resizeTagModels() - called");
            // Get the max height of tag model head style and set all model head with this height
            let maxTagModelHeadHeight = 0;
            const selectorTagModelHead = $(".tagModelOptionHead");
            if (selectorTagModelHead && selectorTagModelHead.length > 0) {
                selectorTagModelHead.each(function() {
                    if ($(this) && $(this).height() > maxTagModelHeadHeight) {
                        maxTagModelHeadHeight = $(this).height();
                    }
                });
                if (maxTagModelHeadHeight) {
                    this.tagModelHeadStyle = "height: " + maxTagModelHeadHeight + "px";
                }
            }

            // Get the max height of tag model body and set all model body height with this height
            let maxTagModelBodyHeight = 0;
            const selectorTagModelBody = $(".tagModelOptionBody");
            if (selectorTagModelBody && selectorTagModelBody.length > 0) {
                selectorTagModelBody.each(function() {
                    if ($(this) && $(this).height() > maxTagModelBodyHeight) {
                        maxTagModelBodyHeight = $(this).height();
                    }
                });
                if (maxTagModelBodyHeight) {
                    this.tagModelBodyStyle = "height: " + maxTagModelBodyHeight + "px";
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "NewTag", "create", !this.isEmptyForm(), to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.cursorPointer {
    cursor: pointer;
}
</style>
